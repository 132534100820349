import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const chatLogin = createAsyncThunk(
  "chatLogin",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const getChatMessgae = createAsyncThunk(
  "getChatMessage",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("chatToken")}`,
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const sendMessage = createAsyncThunk(
  "sendMessage",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("chatToken")}`,
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const seenMessage = createAsyncThunk(
  "sendMessage",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("chatToken")}`,
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    data: null,
    loading: false,
    allMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(chatLogin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(chatLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload?.data;
      })
      .addCase(chatLogin.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getChatMessgae.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getChatMessgae.fulfilled, (state, action) => {
        state.loading = false;
        state.allMessage = action.payload;
      })
      .addCase(getChatMessgae.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default chatSlice.reducer;
