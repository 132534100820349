import { createTheme } from "@mui/material";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#115C8E", // green
      border: "#F4829D",
      borderred: "#EA244F", // red
    },
    secondary: {
      main: "#db3a3e", // red
    },
    primaryText: {
      main: "#4a4a4a", // dark gray text
    },
    white: {
      main: "#fff",
    },
    secondaryText: {
      main: "#8E8E8E", // gray text
    },
    gradiant: {
      main: "#115C8E",
      bggra: "linear-gradient(to right, #115C8E, #F4829D)",
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
    h1: {
      fontSize: "2.5rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.75rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.25rem",
    },
    h6: {
      fontSize: "1rem",
    },
    body1: {
      fontSize: "1rem", // Customize for div
    },
    body2: {
      fontSize: "0.875rem", // Customize for span
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});
