import React from "react";
import style from "./loading.module.css";
import mbbs from "../../Assets/mbbs.png";

const CompanyLoading = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
        }}
      >
        <div
          style={{
            wudth: "8rem",
            height: "8rem",
            borderRadius: "0.5rem",
            overflow: "hidden",
          }}
        >
          <img
            style={{ width: "100%", height: "100%", objectFit: "fill" }}
            src={mbbs}
            alt="logo loading"
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../../Assets/mbbs.png");
            }}
          />
        </div>
        <span className={style.loader}></span>
      </div>
    </div>
  );
};

export default CompanyLoading;
