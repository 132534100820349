// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const login = createAsyncThunk("login", async ({ url, data }) => {
  try {
    const response = await axios.get(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    return response ? response?.data : null;
  } catch (error) {
    return error?.response?.data;
  }
});

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: null,
    loader: false,
  },
  reducers: {
    handleLogout: (state, action) => {
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loader = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loader = false;
        state.userData = action.payload;
      })
      .addCase(login.rejected, (state) => {
        state.loader = false;
      });
  },
});

export const { handleLogout } = authSlice.actions;

export default authSlice.reducer;
