import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = `${process.env.REACT_APP_API}`;

export const API = createApi({
  reducerPath: "API",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      // Set the "Authorization" header with the token from local storage
      const token = `${localStorage.getItem("accessToken")}`;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "Category",
    "aboutUs",
    "packages",
    "blogs",
    "blogDetails",
    "gallery",
    "state",
    "home",
    "Counselling",
    "CounsellingDetail",
    "CourseCategory",
    "CourseDetail",
    "university",
    "universityDetails",
    "stateDetails",
    "company",
    "termCondition",
    "admissionProcess",
    "referEarn",
    "neetExam",
    "contactUs",
    "city",
    "userData",
    "predictor",
    "offeringData",
    "homeAlert",
  ],
  endpoints: (builder) => ({
    // Define your CRUD endpoints here

    // auth
    registerUser: builder.mutation({
      query: ({ data }) => ({
        url: `student-register`,
        method: "POST",
        body: data,
      }),
    }),
    loginUser: builder.mutation({
      query: ({ data }) => ({
        url: `student-login`,
        method: "POST",
        body: data,
      }),
    }),
    forgetPassword: builder.mutation({
      query: ({ data }) => ({
        url: `forgot-password-otp`,
        method: "POST",
        body: data,
      }),
    }),
    otpVerify: builder.mutation({
      query: ({ data }) => ({
        url: `forgot-password-otp-verify`,
        method: "POST",
        body: data,
      }),
    }),

    // about us
    aboutUs: builder.query({
      query: () => `get-about-us`,
      providesTags: ["aboutUs"],
    }),

    // packages
    getAllPackages: builder.query({
      query: () => `get-package`,
      providesTags: ["packages"],
    }),

    // blog
    getAllBlogs: builder.query({
      query: () => `get-blogs`,
      providesTags: ["blogs"],
    }),
    getAllBlogsDetails: builder.query({
      query: ({ slugs }) => `blog-detail/${slugs}`,
      providesTags: ["blogDetails"],
    }),

    // Gallery
    getAllGallery: builder.query({
      query: () => ({
        url: `get-gallery`,
        providesTags: ["gallery"],
      }),
    }),

    // destination states
    getAllDestination: builder.query({
      query: () => ({
        url: `get-state`,
        providesTags: ["state"],
      }),
    }),

    // states details
    getStatesDetails: builder.query({
      query: ({ slugs }) => ({
        url: `get-destination/${slugs}`,
        providesTags: ["stateDetails"],
      }),
    }),

    // home page
    getAllHome: builder.query({
      query: () => ({
        url: `get-home`,
        providesTags: ["home"],
      }),
    }),

    // counselling type
    getAllCounselling: builder.query({
      query: () => ({
        url: `get-counselling-type`,
        providesTags: ["Counselling"],
      }),
    }),

    // counselling details by slug
    getCounsellingByType: builder.query({
      query: ({ slugs }) => ({
        url: `get-counselling/${slugs}`,
        providesTags: ["CounsellingDetail"],
      }),
    }),

    // course category
    getAllCourseCategory: builder.query({
      query: () => ({
        url: `get-category-by-course`,
        providesTags: ["CourseCategory"],
      }),
    }),
    getCourseDetailsBySlug: builder.query({
      query: ({ slugs }) => ({
        url: `course-detail/${slugs}`,
        providesTags: ["CourseDetail"],
      }),
    }),

    // apply online all states
    getAllUniversityByState: builder.query({
      query: ({ slugs, state, type, rate, max, city }) => ({
        url: `get-state-by-university?slug=${slugs}&state_id=${state}&university_type=${type}&ratting=${rate}&max_fee=${max}&city_id=${city}`,
        providesTags: ["university"],
      }),
    }),

    // university details
    getUniversityDetails: builder.query({
      query: ({ slugs }) => ({
        url: `university-detail/${slugs}`,
        providesTags: ["universityDetails"],
      }),
    }),

    // company details
    getCompanyDetails: builder.query({
      query: () => ({
        url: `get-settings`,
        providesTags: ["company"],
      }),
    }),

    // term and condition
    getTermsCondition: builder.query({
      query: () => ({
        url: `get-term-condition`,
        providesTags: ["termCondition"],
      }),
    }),

    // admission process
    getAdmissionProcess: builder.query({
      query: () => ({
        url: `get-admission-process`,
        providesTags: ["admissionProcess"],
      }),
    }),

    // Refer and Earn
    getReferAndEarn: builder.query({
      query: () => ({
        url: `get-footer-link?refer_earn=refer_earn`,
        providesTags: ["referEarn"],
      }),
    }),

    // neet exam
    getNeetExam: builder.query({
      query: () => ({
        url: `get-footer-link?neet_exam=neet_exam`,
        providesTags: ["neetExam"],
      }),
    }),

    // submit form by different type
    updateForm: builder.mutation({
      query: ({ data }) => ({
        url: `enquiry`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["home"],
    }),

    // footer form
    updateFooterForm: builder.mutation({
      query: ({ data }) => ({
        url: `news-letter`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["home"],
    }),

    // book appointment
    bookAppointment: builder.mutation({
      query: ({ data }) => ({
        url: `book-appointment`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["home"],
    }),

    // destination form
    destinationForm: builder.mutation({
      query: ({ data }) => ({
        url: `enquiry`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["home"],
    }),

    //admission form
    admissionForm: builder.mutation({
      query: ({ data }) => ({
        url: `enquiry`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["home"],
    }),

    //user Dashboard program form
    programForm: builder.mutation({
      query: ({ data }) => ({
        url: `enquiry`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["home"],
    }),

    // get city
    getCity: builder.query({
      query: (slugs) => ({
        url: `get-city/${slugs}`,
        providesTags: ["city"],
      }),
    }),

    // get contact-us
    getContactUs: builder.query({
      query: () => ({
        url: `get-contact`,
        providesTags: ["contactUs"],
      }),
    }),

    // user data
    getUserData: builder.query({
      query: () => ({
        url: `student-data`,
        providesTags: ["userData"],
      }),
    }),

    //student profile update
    profileUpdate: builder.mutation({
      query: ({ data }) => ({
        url: `student-profile-update`,
        method: "POST",
        body: data,
      }),
    }),

    // predictor
    getPredictorData: builder.query({
      query: () => ({
        url: `get-neet-predictor`,
        providesTags: ["predictor"],
      }),
    }),
    predictorOtpSend: builder.mutation({
      query: ({ data }) => ({
        url: `predictor-send-otp`,
        method: "POST",
        body: data,
      }),
    }),
    predictorOtpVerify: builder.mutation({
      query: ({ data }) => ({
        url: `neet-predictor-otp-verify`,
        method: "POST",
        body: data,
      }),
    }),
    predictorSubmit: builder.mutation({
      query: ({ data }) => ({
        url: `neet-predictor`,
        method: "POST",
        body: data,
      }),
    }),

    // university filter
    universityFilter: builder.mutation({
      query: ({ data }) => ({
        url: `filter-university`,
        method: "POST",
        body: data,
      }),
    }),

    // serviceOffering
    serviceOffering: builder.query({
      query: () => ({
        url: `get-service-feature`,
        providesTags: ["offeringData"],
      }),
    }),
    // apply-university
    getApplyUniversityData: builder.query({
      query: () => ({
        url: `apply-university`,
        providesTags: ["home"],
      }),
    }),
    // home important news
    getImportantNews: builder.query({
      query: () => ({
        url: `get-importantsms`,
        providesTags: ["homeAlert"],
      }),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useForgetPasswordMutation,
  useOtpVerifyMutation,
  useAboutUsQuery,
  useGetAllPackagesQuery,
  useGetAllBlogsQuery,
  useGetAllGalleryQuery,
  useGetAllBlogsDetailsQuery,
  useGetAllDestinationQuery,
  useGetAllHomeQuery,
  useGetAllCounsellingQuery,
  useGetCounsellingByTypeQuery,
  useGetAllCourseCategoryQuery,
  useGetCourseDetailsBySlugQuery,
  useGetAllUniversityByStateQuery,
  useGetUniversityDetailsQuery,
  useGetStatesDetailsQuery,
  useGetCompanyDetailsQuery,
  useUpdateFormMutation,
  useBookAppointmentMutation,
  useGetTermsConditionQuery,
  useGetAdmissionProcessQuery,
  useGetReferAndEarnQuery,
  useGetNeetExamQuery,
  useDestinationFormMutation,
  useAdmissionFormMutation,
  useProgramFormMutation,
  useGetCityQuery,
  useProfileUpdateMutation,
  useUpdateFooterFormMutation,
  useGetContactUsQuery,
  useGetUserDataQuery,
  useGetPredictorDataQuery,
  usePredictorOtpSendMutation,
  usePredictorOtpVerifyMutation,
  usePredictorSubmitMutation,
  useUniversityFilterMutation,
  useServiceOfferingQuery,
  useGetApplyUniversityDataQuery,
  useGetImportantNewsQuery,
} = API;
