import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { API } from "./RTK Query/API";
import {thunk} from "redux-thunk";
import { setupListeners } from "@reduxjs/toolkit/query";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Ignore specific action types from serialization check
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    })
      .concat(API.middleware)
      .concat(thunk), // Add RTK Query middleware
  devTools: process.env.NODE_ENV !== "production",
});
setupListeners(store.dispatch);
export { store };
