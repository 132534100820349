// ** Reducers Slice Imports
import { API } from "./RTK Query/API";
import { setupListeners } from "@reduxjs/toolkit/query";
import authSlice from "./Slice/authSlice";
import chatSlice from "./Slice/chatSlice";

const rootReducer = {
  authSlice,
  chatSlice,
  [API.reducerPath]: API.reducer,
};

export default rootReducer;

setupListeners(API.middleware);
